<script>
import errorsMixin from '@/mixins/errors.mixin'

export default {
  render() {
    return this.$scopedSlots.default({
      processing: this.processing,
      submit: this.submit,
      showing: this.showing,
      toggle: this.toggle
    })
  },

  inject: ['svc'],
  
  mixins: [errorsMixin],

  data: () => ({
    processing: false,
    showing: false
  }),

  methods: {
    updateShowing(val) {
      this.showing = val
    },

    toggle() {
      this.showing = !this.showing
    },

    async submit(data) {
      try {
        this.processing = true;
        if( data.id ) {
          await this.svc.update(data);
          this.$notify({text: 'Updated', type: 'success'});
        } else {
          await this.svc.create(data);
          this.$notify({text: 'Created', type: 'success'});
        }
        this.toggle();
        this.$emit('reload-list');
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.processing = false
      }
    }
  }
}
</script>
